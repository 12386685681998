import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

import Layout from "../components/layout"
import { Section, Wrapper } from "../components/styles/container"
import "../components/styles/klaus-gridski.css"

import Hero from "../images/contact.jpg"
import HeroTab from "../images/contact_tablet.jpg"
import HeroMob from "../images/contact_mobile.jpg"

/******************************************************************************************* STYLED
 *************************************************************************************************/

const HeroStyle = styled.img`
  width: 100%;
  height: 35vh;
  object-fit: cover;
  object-position: center;
  @media only screen and (min-width: 48em) {
    height: 40vh;
  }
  /* Width in PX > 1200px */
  @media only screen and (min-width: 75em) {
    height: 60vh;
  }
`

const Headline = styled.h2`
  padding: 0;
  margin-bottom: 1.5rem;
`

const Subline = styled.h3`
  max-width: 450px;
  padding: 0;
`

const MarginNeeded = styled.div`
  margin: 7rem 0 0 0;
  /* Width in PX > 920px */
  /* ==== = BIG = ==== */
  @media only screen and (min-width: 62em) {
    margin: 0 10rem 0 0;
  }
`

const Line = styled.div`
  height: 100%;
  :after {
    /* Width in PX > 920px */
    /* ==== = BIG = ==== */
    @media only screen and (min-width: 62em) {
      display: block;
      content: "";
      border-right: 1.5px solid #a7b2a5;
      width: 1px;
      height: 200px;
      margin: 2rem;
    }
  }
`

/******************************************************************************************** LAYOUT COMPONENT
 **************************************************************************************************/

const Contact = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />

      <picture>
        <source
          role="presentation"
          srcset={HeroMob}
          media="(max-width: 30em)"
        />
        <source
          role="presentation"
          srcset={HeroTab}
          media="(min-width: 30em) and (max-width: 75em)"
        />
        <HeroStyle role="presentation" src={Hero} />
      </picture>

      <Section>
        <Wrapper className="flex vertical bg-horizontal between l-around bg-v-center">
          <div>
            <Headline>Hallo,</Headline>
            <Subline>
              wenn Sie Fragen haben, einen Beratungstermin vereinbaren möchten
              oder ein sonstiges Anliegen haben, melden Sie sich gern. Ich freue
              mich auf Sie!
            </Subline>
          </div>
          <Line></Line>
          <div className="flex vertical bg-horizontal bg-v-end between">
            <MarginNeeded>
              <strong>Christina Pabst</strong>
              <p>Eichendorffstraße 2a</p>
              <p>36304 Alsfeld</p>
            </MarginNeeded>
            <div className="flex vertical">
              <a
                title="Christina Pabst Coaching anrufen"
                href="tel:01726292865"
              >
                Mobil: 0172 6292 865
              </a>
              <a
                title="Mail an Christina Pabst Coaching"
                href="mailto:hallo@christinapabst.de"
              >
                hallo@christinapabst.de
              </a>
            </div>
          </div>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default Contact
